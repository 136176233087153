.App {
  
}
.App h1{
  left: 0;
  line-height: 200px;
  margin-top: -100px;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 100%;
  font-weight: bolder;
  color: orangered;
  font-size: 2.5rem;
}


